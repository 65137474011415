import moment from "moment-timezone";

export const isOnCallTimesValid = (
  selectedDates,
  selectedOnCallTimes,
  isOnCallTimesOnNextDay
) => {
  if (
    selectedOnCallTimes.startTime === null ||
    selectedOnCallTimes.endTime === null
  ) {
    return false;
  }
  return selectedDates.every(shift => {
    const shiftStart = moment(shift.startTime);
    const shiftEnd = moment(shift.endTime);

    // Create moment objects for the OnCall times, starting on shift start date
    let onCallStart = moment(shiftStart)
      .hour(selectedOnCallTimes.startTime.hour())
      .minute(selectedOnCallTimes.startTime.minute())
      .second(0)
      .millisecond(0);

    let onCallEnd = moment(shiftEnd)
      .hour(selectedOnCallTimes.endTime.hour())
      .minute(selectedOnCallTimes.endTime.minute())
      .second(0)
      .millisecond(0);

    // If isOnCallTimesOnNextDay is true or start time is before any shift start time,
    // move both times to next day
    if (isOnCallTimesOnNextDay) {
      onCallStart.add(1, "day");
      onCallEnd.add(1, "day");
    }

    // If end is before start after all adjustments, move end to next day
    if (onCallEnd.isBefore(onCallStart)) {
      onCallEnd.add(1, "day");
    }

    // Calculate max end time (23:59 of the day after shift start)
    const maxEndTime = shiftEnd
      .clone()
      .add(1, "day")
      .set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

    // Validate that:
    // 1. onCallStart is after shift end
    // 2. onCallEnd is before or at maxEndTime
    // 3. onCallStart is before onCallEnd
    return (
      onCallStart.isSameOrAfter(shiftEnd) &&
      onCallEnd.isSameOrBefore(maxEndTime) &&
      onCallStart.isBefore(onCallEnd)
    );
  });
};

export const onOnCallTimeChange = (
  handleShiftUpdate,
  dateType,
  selectedOnCallTimes,
  updateVal,
  shiftTimes,
  isOnCallTimesOnNextDay
) => {
  isOnCallTimesOnNextDay = false;
  let startTime = moment(selectedOnCallTimes.startTime).clone();
  let endTime = moment(selectedOnCallTimes.endTime).clone();
  const shiftStarts = shiftTimes
    .map(shift => moment(shift.startTime))
    .sort((a, b) => b.format("HH:mm").localeCompare(a.format("HH:mm")));

  const shiftStart = shiftStarts[0];

  const [hour, minute] = updateVal.split(":");

  // First, ensure both times are initially on the same day as shift start
  startTime = startTime.set({
    year: shiftStart.get("year"),
    month: shiftStart.get("month"),
    date: shiftStart.get("date")
  });

  endTime = endTime.set({
    year: shiftStart.get("year"),
    month: shiftStart.get("month"),
    date: shiftStart.get("date")
  });

  // Update the time that's changing
  if (dateType === "startTime") {
    startTime = startTime.set({
      hour: parseInt(hour),
      minute: parseInt(minute),
      second: 0,
      millisecond: 0
    });
  }

  if (dateType === "endTime") {
    endTime = endTime.set({
      hour: parseInt(hour),
      minute: parseInt(minute),
      second: 0,
      millisecond: 0
    });
  }

  // Check if start time should be on next day
  if (startTime.format("HH:mm") < shiftStart.format("HH:mm")) {
    isOnCallTimesOnNextDay = true;
  }

  // Apply next day adjustment if needed
  if (isOnCallTimesOnNextDay) {
    startTime.add(1, "day");
    endTime.add(1, "day");
  }

  // If end is before start after all adjustments, move end to next day
  if (endTime.isBefore(startTime)) {
    endTime.add(1, "day");
  }

  handleShiftUpdate({
    selectedOnCallTimes: { startTime, endTime },
    isOnCallTimesOnNextDay
  });
};

export const getFormattedOnCallTimeDuration = selectedOnCallTimes => {
  const onCallStartTime = moment(selectedOnCallTimes.startTime);
  const onCallEndTime = moment(selectedOnCallTimes.endTime);

  const duration = moment.duration(onCallEndTime.diff(onCallStartTime));

  return duration.format("H [hrs] m [mins]");
};
